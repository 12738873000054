<template>
  <div class="product">
    <h1>VIP高速套餐</h1>
    <p
        class="desc"
    >Transgle网路加速神器直接连接路由器，底层网络直连，无需下载APP，再也不必担心隐私安全问题。SDWAN技术提供流畅用户体验，再也无需国内国外来回切换，让你彻底摆脱VPN。</p>
    <p
        class="desc"
    >Transgle为各位游戏玩家提供游戏专线，独立优化IP池，身在海外玩国服游戏再也不卡机、不延迟、不掉线，完美支持加速王者荣耀、和平精英、英雄联盟 、魔兽世界等国服游戏。在玩游戏的同时还可支持YY语音、听音乐、看视频。</p>
    <div class="vip_box">
      <div class="box">
        <img src="../../assets/home_ic_line.png" alt/>
        <span>精品路线</span>
      </div>
      <div class="box">
        <img src="../../assets/home_ic_hd.png" alt/>
        <span>高清播放</span>
      </div>
      <div class="box">
        <img src="../../assets/home_ic_service.png" alt/>
        <span>客服优先</span>
      </div>
      <div class="box">
        <img src="../../assets/home_ic_discounts.png" alt/>
        <span>物超所值</span>
      </div>
    </div>
    <div class="product_box" v-if="productLists.length>0">
      <div
          :class="[{'box_active':mealsIndex==index},'box']"
          @click="choseMeals(index)"
          v-for="(item,index) in productLists"
          :key="item.order_id"
          :style="{marginRight:(index+1)%2==0?0:'4%'}"
      >
        <h4 class="title">{{ item.title }}</h4>
        <p class="price_wrap" v-if="item.id==13">
          <span class="price price_red">{{ item.price / 100 }}</span>
          <span class="currency currency_red">{{ item.currency }}</span>
          <!--          <span class="days">30天</span>-->
        </p>
        <p class="price_wrap" v-else-if="item.id==12">
          <span class="price">{{ item.price / 100 }}</span>
          <span class="currency">{{ item.currency }}</span>
          <!-- <span class="line_through_price ">原价 349AUD</span> -->
          <!--          <span class="days">30天</span>-->
        </p>
        <p class="price_wrap" v-else>
          <span class="price">{{ item.price / 100 }}</span>
          <span class="currency">{{ item.currency }}</span>
          <!--          <span class="days">30天</span>-->
        </p>
        <!-- <p class="device">
          设备登录限制
          <span class="limit">{{item.device_limit}}</span> 台
        </p>-->
        <p>
          <!--          类型-->
          <span class="type">{{ item.type_text }}</span>
        </p>
        <p>
          区域
          <span class="region">{{ item.region.region }}</span>
        </p>
        <p class="device">{{ item.device_limit === 0 ? '同时上线多台设备' : '同时上线' + item.device_limit + '台设备' }}</p>
        <p class="device">{{ item.description.split("|")[0] }}</p>
        <p class="device">{{ item.description.split("|")[1] }}</p>
        <span v-if="item.is_recommend==1" class="intro_logo">推荐</span>
      </div>
    </div>
    <router-link to="/recharge">
      <button class="buy_now">购买高速套餐</button>
    </router-link>
  </div>
</template>
<script>
import {getProductListApi} from "api/Recharge";

export default {
  data() {
    return {
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      productLists: [],
      mealsIndex: 0, //当前选择的套餐序列
    };
  },
  methods: {
    //选择套餐
    choseMeals(index) {
      this.mealsIndex = index;
    },
  },
  mounted() {
    getProductListApi().then(res => {
      if (res.code == 0) {
        this.productLists = res.data;
        const index = this.productLists.findIndex(
            item => item.is_recommend == 1
        );
        this.mealsIndex = index;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.product {
  padding: 0 16px;

  h1 {
    font-size: 26px;
    color: #202020;
    margin: 30px 0;
    font-weight: 500;
    text-align: center;
  }

  .desc {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 28px;
  }

  .vip_box {
    @include flex_between;

    .box {
      margin-top: 20px;
      width: 20%;
      text-align: center;

      img {
        width: 100%;
        padding-bottom: 10px;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        color: #202020;
        line-height: 20px;
      }
    }
  }

  .product_box {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    border-bottom: 1px dashed #bcc5d4;

    .box_active {
      border: 2px solid #517cfc;
    }

    .box {
      flex-direction: column;
      width: 48%;
      text-align: center;
      margin-top: 45px;
      margin-right: 4%;
      box-sizing: border-box;
      padding: 20px 10px 5px 10px;
      background: #edf9ff;
      border-radius: 4px;
      position: relative;

      .title {
        font-size: 18px;
        font-weight: 500;
        color: #202020;
        line-height: 25px;
      }

      p {
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .currency,
      .limit,
      .price,
      .type,
      .region {
        color: #517cfc;
      }

      .price_wrap {
        font-size: 16px;

        .line_through_price {
          font-size: 12px;
          color: #517cfc;
          text-decoration: line-through;
          text-decoration-color: #ff6854;
          display: block;
        }

        .price {
          font-size: 28px;
          font-weight: 700;
        }

        .price_red {
          color: #ff6854;
        }

        .currency {
          font-size: 12px;
        }

        .currency_red {
          color: #ff6854;
        }
      }

      .intro_logo {
        display: inline-block;
        padding: 3px 5px;
        color: #fff;
        font-size: 16px;
        position: absolute;
        right: 0;
        top: 0;
        border-bottom-left-radius: 5px;
        background: #517cfc;
      }
    }
  }

  .buy_now {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #517cfc;
    font-size: 16px;
    font-weight: 500;
    color: #517cfc;
    margin: 20px 0;
    background: #fff;
  }
}
</style>