<template>
  <div class="home">
    <Banner/>
    <Accelerate/>
    <ReasonChose/>
    <Install/>
    <Product/>
    <div class="footer">2018-2020 Transgle. All rights reserved</div>
  </div>
</template>
<script>
import Banner from "./Banner";
import Accelerate from "./Accelerate";
import ReasonChose from "./ReasonChose";
import Install from "./Install";
import Product from "./Product";

export default {
  name: "Home",
  components: {
    Banner,
    Accelerate,
    ReasonChose,
    Install,
    Product
  },
  mounted() {
    if (this.$route.query.token) {
      this.$store.dispatch({
        type: "changeLoginStatus",
        login: true
      });
      sessionStorage.setItem("token", this.$route.query.token);
    }
    if (this.$route.query.jump_url) {
      this.$router.push({path: "/" + this.$route.query.jump_url});
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 74px;
  line-height: 74px;
  background: #4353b6;
  color: #fff;
  text-align: center;
  font-size: 16px;
}
</style>
