<template>
  <div class="reason_chose">
    <div class="title">
      <h1>为什么选择Transgle加速器？</h1>
    </div>
    <div class="reason_box">
      <div class="box box1">
        <img src="../../assets/home_global.png" alt />
        <h4>全球网络节点覆盖</h4>
        <p>Transgle拥有覆盖全球的网络节点，以此来保证可以高速访问国内网络。通过Transgle您可以观看中国的视频、直播、听中国版权音乐、甚至玩国服游戏等。</p>
        <router-link to="/recharge">
<!--          <span>一键解锁</span>-->
        </router-link>
      </div>
      <div class="box box2">
        <img src="../../assets/home_mianfei.png" alt />
        <h4>提供最优加速体验</h4>
        <p>Transgle将为您提供最优加速体验，自建内网，速度稳定有保障，秒杀同行，提速 100ms以上。</p>
        <router-link to="/recharge">
<!--          <span>一键解锁</span>-->
        </router-link>
      </div>
      <div class="box box3">
        <img src="../../assets/home_ai.png" alt />
        <h4>线路智能分配技术</h4>
        <p>我们将根据您的网络情况实时进行线路监控服务，随时监控线路的可用及延迟，为用户分配最优的加速线路进行连接。</p>
        <router-link to="/recharge">
<!--          <span>一键解锁</span>-->
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "accelerate",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.reason_chose {
  padding: 30px 16px;
  .title {
    display: flex;
    justify-content: center;
    h1 {
      line-height: 35px;
      text-align: justify;
      font-size: 26px;
      color: #202020;
      padding: 30px 0;
      font-weight: 500;
    }
  }
  .reason_box {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box:nth-child(3) {
      margin-bottom: 0;
    }
    .box {
      width: 90%;
      background: #ffffff;
      box-shadow: 0px 10px 15px 0px rgba(181, 193, 203, 0.3);
      border-radius: 10px;
      margin-bottom: 23px;
      img {
        width: 100%;
      }
      h4 {
        margin: 10px 0;
        font-size: 20px;
        color: #202020;
      }
      p {
        font-family: PingFangSC-Regular, PingFang SC;
        padding: 0 35px;
        font-size: 12px;
        color: #4a4a4a;
        line-height: 17px;
        text-align: justify;
      }
      a {
        display: inline-block;
        font-size: 16px;
        color: #517cfc;
        line-height: 22px;
        padding-bottom: 20px;
        padding-top: 40px;
        text-decoration: underline;
      }
    }
  }
}
</style>