<template>
  <div class="banner">
    <div class="header">
      <img class="logo" src="../../assets/logo.png"/>
      <h1>天翼传奇能永久帮助海外华人解锁全家所有设备、中国应用免除地域限制，尊享线路，自动连接</h1>
      <div class="des">
        <p>Transgle 最新技术的免翻墙海外回国加速器，一机解锁+加速家中所有的设备，海外华人的必备神器。在海外需要回国访问中国音视频和网站，就用回国加速领先品牌Transgle。畅通访问和加速音视频、国内网站，满足你回国看视频、听音乐和访问网页等各种需求，百万用户的选择。</p>
        <p>APP端支持手机电脑全平台下载使用，好评如潮，专业回国线路，不限流量，稳定快速。</p>
      </div>
      <div class="banner_btn_box">
        <router-link to="/recharge">
          <button class="btn">
            <img style="width:28px;" src="../../assets/Router.png"/>
            <span>天翼网盒</span>
          </button>
        </router-link>
        <router-link to="/recharge">
          <button class="btn">
            <img style="width:28px;" src="../../assets/iphone.png"/>
            <span>专属APP</span>
          </button>
        </router-link>
      </div>
      <div class="free_btn">
        <router-link to="/register">
          <button class="btn">免费注册</button>
        </router-link>
      </div>

      <img class="banner_logo" src="../../assets/banner.png" alt/>
    </div>
  </div>
</template>
<script>
export default {
  name: "Banner",
  data() {
    return {
      toolList: []
    };
  },
  mounted() {
  },
  methods: {}
};
</script>
<style lang='scss' scoped>
.banner {
  .header {
    position: relative;
    padding: 16px;
    padding-bottom: 24px;
    background: linear-gradient(
            180deg,
            rgba(137, 179, 254, 0.2) 0%,
            rgba(24, 0, 255, 0.2) 100%
    );

    .logo {
      width: 170px;
      height: 36px;
    }

    h1 {
      font-weight: 400;
      font-size: 22px;
      color: #202020;
      line-height: 45px;
      margin-top: 56px;
      margin-bottom: 27px;
    }

    .des {
      font-size: 16px;
      color: #202020;
      line-height: 34px;
    }

    .banner_btn_box {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

    .free_btn {
      display: flex;
      justify-content: center;

      button {
        background: transparent;
        border: 1px solid #517cfc;
        color: #517cfc;
        margin-top: 37px;
        width: 190px;
      }
    }

    .btn {
      width: 160px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #517cfc;
      border-radius: 5px;
      font-size: 16px;
      color: #fff;

      span {
        margin-left: 10px;
      }
    }

    .banner_logo {
      width: 193px;
      position: absolute;
      right: -1px;
      z-index: -1;
      bottom: 86px;
      opacity: 0.6;
    }
  }
}
</style>